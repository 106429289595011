<template>
  <div class="app-container">
    <!--工具栏-->
    <div class="head-container">
      <el-input v-model="query.name" clearable placeholder="根据名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" @change="toQuery" clearable style="width: 150px;" />
      <el-select v-model="query.enabled" filterable clearable placeholder="启用" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="是" :value="true" />
        <el-option label="否" :value="false" />
      </el-select>
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <eForm ref="form" :is-add="isAdd" :dicts="dicts" />
    <!--表格渲染-->
    <el-table :data="data" row-key="id" v-loading="loading">
      <el-table-column prop="name" label="系列名称" />
      <el-table-column prop="brand.name" label="品牌" />
      <el-table-column prop="displayNo" label="排序号" align="center" width="100" />
      <el-table-column prop="enabled" label="启用" align="center" width="100" :formatter="v=>{return v.enabled?'是':'否'}" />
      <el-table-column width="100px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)" v-if="user.entId===scope.row.entId">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" v-if="user.entId===scope.row.entId">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" :disabled="scope.row.children && scope.row.children.length>0" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { del } from "@/api/series";
import checkPermission from "@/utils/permission";
import { parseTime } from "@/utils/index";
import initData from "@/mixins/initData";
import initDict from "@/mixins/initDict";
import eForm from "./form";
import { mapGetters } from "vuex";

export default {
  components: { eForm },
  mixins: [initData, initDict],
  data() {
    return {
      delLoading: false,
      loading: false,
      query: {
        value: null,
        brandId: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"])
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "api/series/tree";
      // this.params = {
      //   page: this.page,
      //   size: this.size,
      //   sort: "displayNo,desc"
      // };
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: "displayNo,desc" },
        this.query
      );
      // const query = this.query;
      // if (query.value) {
      //   this.params["name"] = query.value;
      // }
      // if (query.brandId) {
      //   this.params["brandId"] = query.brandId;
      // }
      return true;
    },
    add() {
      this.isAdd = true;
      const _this = this.$refs.form;
      _this.restForm();
    },
    //修改
    edit(data) {
      // console.log(data);
      this.isAdd = false;
      const _this = this.$refs.form;
      delete data.children;
      delete data.parent;
      _this.restForm(JSON.parse(JSON.stringify(data)));
    },
    changeExpand() {
      this.expand = !this.expand;
      this.init();
    },
    //删除当前行数据功能
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
  },
};
</script>
<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增':'修改'" :close-on-click-modal="false" @closed="cancel" width="480px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" v-if="form" :disabled="disabled">
        <el-form-item label="系列名称" prop="name">
          <el-input v-model.trim="form.name" :maxlength="20" />
        </el-form-item>
        <el-form-item v-if="form.pid !== 0" label="上级系列">
          <tree-picker ref="pTree" v-model="form.pid" url="api/series/tree" :exclude-keys="isAdd ? null : [form.id]" placeholder="选择上级系列，不选择则为顶级系列" style="width:100% " @change="handleSeries" />
        </el-form-item>
        <el-form-item label="品牌管理" prop="brandId">
          <quick-select v-model="form.brandId" filterable url="api/brand" placeholder="请选择" style="width:100%" :disabled="form.pid" />
        </el-form-item>
        <el-form-item label="排序" prop="displayNo">
          <el-input-number v-model="form.displayNo" :min="0" :max="9999" :step="1" :precision="0" controls-position="right" style="width:50%" />
        </el-form-item>
        <el-form-item label="启用状态">
          <el-switch v-model="form.enabled" size="mini" />
        </el-form-item>
        <el-form-item label="系列说明" prop="remark">
          <el-input v-model.trim="form.remark" type="textarea" resize="none" :rows="5" :maxlength="100" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/series";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      form: {
        name: null,
        pid: null,
        brandId: null,
        displayNo: "",
        remark: null
      },
      rules: {
        name: [
          { required: "true", message: "系列名称为必填项", trigger: "blur" }
        ],
        brandId: [
          { required: "true", message: "品牌管理为必选项", trigger: "change" }
        ],
        code: [
          { required: "true", message: "系列编码为必填项", trigger: "blur" }
        ],
        pid: [
          { required: "true", message: "上级系列为必选项", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    getData() {
      let d = JSON.parse(JSON.stringify(this.form));
      let ps = {
        values: [],
        labels: []
      };
      if (d.pid) {
        ps = this.$refs.pTree.getFullInfo(d.pid);
      }
      ps.values.push(d.id || "_self");
      ps.labels.push(d.name);
      d.fullPath = "," + ps.values.join(",") + ",";
      d.fullName = ps.labels.join("/");
      return d;
    },
    handleSeries(val) {
      this.form.brandId = val.brandId;
    },
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    // 确认保存
    doAdd() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          let d = this.getData();
          add(d)
            .then(res => {
              this.dialog = false;
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    // 修改
    doEdit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let d = this.getData();
          this.loading = true;
          edit(d)
            .then(res => {
              this.dialog = false;
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      this.form = form || {
        name: null,
        pid: null,
        brandId: null,
        displayNo: "",
        remark: null
      };
    }
  }
};
</script>

